import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ParametersComponent} from './parameters.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule],
  declarations: [ParametersComponent],
  exports: [ParametersComponent]
})
export class ParametersModule {
}
