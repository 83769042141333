import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {colorStatusBar, Tools} from '../../tools';
import {ActivatedRoute, Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UsersService} from '../../../api';
import {setApiKey} from '../../app.component';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
})
@colorStatusBar('#202049', true)
export class ParametersComponent implements OnInit {

  @Input() backroute: string;

  language = Tools.getLanguage();
  translate = null;
  apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : null;
  invite = localStorage.getItem('invite') ? localStorage.getItem('invite') : null;
  showAllPredictionCurve = localStorage.getItem('showAllPredictionCurve') ? localStorage.getItem('showAllPredictionCurve') : false;
  enablePushNotification = localStorage.getItem('pushEnable') ? localStorage.getItem('pushEnable') : false;

  constructor(private tools: Tools, private router: Router, private navCtrl: NavController,
              public ref: ChangeDetectorRef, private statusBar: StatusBar,
              public usersService: UsersService, private route: ActivatedRoute) {
    this.translate = tools.translate;
  }

  ngOnInit() {
    console.log(this.enablePushNotification);
  }

  setLanguage(value) {
    this.language = this.tools.setLanguage(value);
    if (this.apiKey != null) {
      this.navCtrl.navigateBack('/tabs/home');
      return;
    }
    if (this.invite != null) {
      this.navCtrl.navigateBack('/park-list');
      return;
    }
    this.navCtrl.navigateBack('/login');
  }

  setShowAllPredictionCurve(value) {
    console.log(value);
    this.showAllPredictionCurve = value;

    if (this.showAllPredictionCurve) {
      localStorage.setItem('showAllPredictionCurve', 'true');
    } else {
      localStorage.removeItem('showAllPredictionCurve');
    }
  }

  setPushNotification(value) {
    this.enablePushNotification = value;
    if (this.enablePushNotification) {
      let enablingCallback = (ids) => {
        setApiKey(this.usersService, this.apiKey).putNotify({enable: true, notificationId: ids.userId}).subscribe();
      };
      window['plugins'].OneSignal.setSubscription(true);
      window['plugins'].OneSignal.getIds(enablingCallback.bind(this));
      localStorage.setItem('pushEnable', 'true');
    } else {
      window['plugins'].OneSignal.setSubscription(false);
      setApiKey(this.usersService, this.apiKey).putNotify({enable: false, notificationId: ''}).subscribe();
      localStorage.setItem('pushEnable', 'false');
    }
    console.log(this.enablePushNotification);
    console.log(localStorage.getItem('pushEnable'));
  }

  goBack() {
    this.navCtrl.setDirection('back', true, 'back');
    this.router.navigate([this.backroute], {relativeTo: this.route}); // 🙈
  }

  goToChangePassword() {
    this.navCtrl.setDirection('forward', true, 'forward');
    this.router.navigate(['change-password'], {relativeTo: this.route}); // 🙈
  }

  goToFriends() {
    this.navCtrl.setDirection('forward', true, 'forward');
    this.router.navigate(['friends'], {relativeTo: this.route}); // 🙈
  }

  disconnect() {
    if (window['plugins']) {
      window['plugins'].OneSignal.setSubscription(false);
    }
    setApiKey(this.usersService, this.apiKey).putNotify({enable: false, notificationId: ''}).subscribe();
    localStorage.clear();
    this.navCtrl.setDirection('forward', true, 'forward');
    this.router.navigate(['user-change-password'], {relativeTo: this.route});
  }


}
